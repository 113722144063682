import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Menu from './Menu';
import Hotel from './Hotel';
import WatchTv from './WatchTv';
import FullscreenVideo from './FullscreenVideo';


function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/Hotel" element={<Hotel />} />
        <Route path="/WatchTv" element={<WatchTv />} />
        {/* <Route path="/watch" element={<WatchChannel />} /> */}
        <Route path="/fullscreen" element={<FullscreenVideo />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

