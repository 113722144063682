import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import mylogo from './assets/images/hippostream.png';
import icWatchTv from './assets/icons/watchtv.png';

const Menu = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openedYouTube, setOpenedYouTube] = useState(false);
  const menuItems = [
    { name: 'Watch TV', icon: require('./assets/icons/watchtv.png'), path: '/watchtv' },
    { name: 'Hotel Info', icon: require('./assets/icons/hotelinfo.png'), path: '/Hotel' },
    { name: 'Messages', icon: require('./assets/icons/messages.png'), path: '/Messages' },
    { name: 'YouTube xx', icon: require('./assets/icons/youtube.png'), path: 'vnd.youtube://' },
    { name: 'Netflix', icon: require('./assets/icons/netflix.png'), path: 'netflix://' }
  ];
  
  
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    menuRef.current.focus();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && openedYouTube) {
        navigate('/'); // Navigate to the home page
        setOpenedYouTube(false); // Reset the state
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [openedYouTube, navigate]);

  // const handleNavigation = (path) => {
  //   if (path === 'youtube') {
  //     setOpenedYouTube(true);
  //     setTimeout(() => {
  //       window.location.href = 'vnd.youtube://';
  //     }, 1);
  //   } else if (path === 'netflix') {
  //     setTimeout(() => {
  //       window.location.href = 'vnd.netflix://'; // Navigate to Netflix app
  //     }, 1);
  //   } else {
  //     navigate(path);
  //   }
  // };

  const handleNavigation = (path) => {
    if (path === 'vnd.youtube://') {
      window.open('vnd.youtube://', '_self');
    } else if (path === 'netflix://') {
      window.open('netflix://', '_self');
    } else {
      navigate(path);
    }
  };
  
  

  const handleKeyDown = (e) => {
    e.preventDefault(); // Prevent default behavior
    switch (e.key) {
      case 'ArrowRight':
        setSelectedIndex((selectedIndex + 1) % menuItems.length);
        break;
      case 'ArrowLeft':
        setSelectedIndex((selectedIndex - 1 + menuItems.length) % menuItems.length);
        break;
      case 'Enter':
        handleNavigation(menuItems[selectedIndex].path);
        break;
      case 'Backspace':
      case 'Escape':
        navigate('/'); // Navigate to the home page
        break;
      default:
        break;
    }
  };

  return (
    <div className='general-container'>
      <div
        className="menu-container"
        onKeyDown={handleKeyDown}
        tabIndex="0"
        ref={menuRef}
        role="menu"
      >
        <div className="menu-top">
          <img src={mylogo} width='200px' alt='HippoStream Logo' />
        </div>

        <div className='welcome-messages'>Welcome to Hotel Lazada Esperalda</div>
        <div className="menu-footer">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`menu-item ${selectedIndex === index ? 'selected' : ''}`}
              aria-selected={selectedIndex === index}
              role="menuitem"
              onClick={() => handleNavigation(item.path)}
            >
              <img src={item.icon} alt={item.name} />
           
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
