import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const WatchTv = () => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const videoRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const channels = [
    { id: 1, name: 'RCTI', videoSrc: 'https://sindikasi.inews.id/embed/video/YWdlbnQ9ZGVza3RvcCZ1cmw9aHR0cHMlM0ElMkYlMkZlbWJlZC5yY3RpcGx1cy5jb20lMkZsaXZlJTJGcmN0aSUyRmluZXdzaWQmaGVpZ2h0PTEwMCUyNSZ3aWR0aD0xMDAlMjU=' },
    { id: 2, name: 'GTV', videoSrc: 'https://sindikasi.inews.id/embed/video/YWdlbnQ9ZGVza3RvcCZ1cmw9aHR0cHMlM0ElMkYlMkZlbWJlZC5yY3RpcGx1cy5jb20lMkZsaXZlJTJGZ3R2JTJGaW5ld3NpZCZoZWlnaHQ9MTAwJTI1JndpZHRoPTEwMCUyNQ==' },
    { id: 3, name: 'MNCTV', videoSrc: 'https://sindikasi.inews.id/embed/video/YWdlbnQ9ZGVza3RvcCZ1cmw9aHR0cHMlM0ElMkYlMkZlbWJlZC5yY3RpcGx1cy5jb20lMkZsaXZlJTJGbW5jdHYlMkZpbmV3c2lkJmhlaWdodD0xMDAlMjUmd2lkdGg9MTAwJTI1' },
    { id: 4, name: 'HBO', videoSrc: 'http://fpt.vthanhtivi.pw/hbo/index.m3u8' },
  ];

  const handleChannelClick = (channel) => {
    navigate('/fullscreen', { state: { videoSrc: channel.videoSrc, channelName: channel.name } });
  };

  const scrollIntoView = (index) => {
    const container = menuRef.current;
    if (container) {
      const item = container.children[index];
      if (item) {
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        item.focus(); // Ensure the item is focused
      }
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        setFocusedIndex((prev) => {
          const newIndex = prev > 0 ? prev - 1 : channels.length - 1;
          scrollIntoView(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowDown':
        setFocusedIndex((prev) => {
          const newIndex = prev < channels.length - 1 ? prev + 1 : 0;
          scrollIntoView(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        if (isFullscreenMode) {
          navigate('/fullscreen', { state: { videoSrc: channels[focusedIndex].videoSrc, channelName: channels[focusedIndex].name } });
        } else {
          setIsFullscreenMode(true);
        }
        break;
      case 'Escape':
        if (isFullscreenMode) {
          setIsFullscreenMode(false);
        } else {
          navigate('/'); // Navigate to the home page or previous page
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedIndex, isFullscreenMode]);

  useEffect(() => {
    if (!isFullscreenMode) {
      // Ensure focus is managed properly when exiting fullscreen mode
      scrollIntoView(focusedIndex);
    }
  }, [focusedIndex, isFullscreenMode]);

  return (
    <div className='general-container2'>
      <div className='watchtv-container'>
        <div className='watchtv-channels' ref={menuRef}>
          {channels.map((channel, index) => (
            <div
              key={channel.id}
              className={`channel-item ${focusedIndex === index ? 'focused' : ''}`}
              onClick={() => handleChannelClick(channel)}
              tabIndex="0"
              role="button"
              aria-selected={focusedIndex === index}
            >
              {channel.name}
            </div>
          ))}
        </div>
        <div className='watchtv-display-video'>
          <iframe
            ref={videoRef}
            src={channels[focusedIndex].videoSrc}
            title={channels[focusedIndex].name}
            width="30%"
            // height="30%"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default WatchTv;
