import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css';
import myimg from './assets/images/maya.jpg';
import myimg2 from './assets/images/rst.jpg';

function Hotel() {
  const [activeMenu, setActiveMenu] = useState('Room');
  const [isContentFocused, setIsContentFocused] = useState(false);
  const menuItems = ['Room', 'Restaurant', 'Facilities'];
  const menuRefs = useRef([]);
  const contentRef = useRef(null);
  const navigate = useNavigate();

  // Define key codes as constants
  const RC_LEFT = 37;
  const RC_UP = 38;
  const RC_RIGHT = 39;
  const RC_DOWN = 40;
  const RC_ENTER = 13;
  const RC_EXIT = 10182;

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    setIsContentFocused(false); // Reset content focus when changing the menu
  };

  const handleKeyDown = (event) => {
    const currentIndex = menuItems.indexOf(activeMenu);
  
    if (isContentFocused) {
      switch (event.keyCode) {
        case RC_UP:
          if (contentRef.current) {
            contentRef.current.scrollBy(0, -50); // Scroll up
          }
          break;
        case RC_DOWN:
          if (contentRef.current) {
            contentRef.current.scrollBy(0, 50); // Scroll down
          }
          break;
        case RC_LEFT:
          setIsContentFocused(false); // Move focus back to the sidebar menu
          menuRefs.current[currentIndex].focus();
          break;
        default:
          break;
      }
    } else {
      switch (event.keyCode) {
        case RC_DOWN:
          const nextIndex = (currentIndex + 1) % menuItems.length;
          setActiveMenu(menuItems[nextIndex]);
          menuRefs.current[nextIndex].focus();
          break;
        case RC_UP:
          const prevIndex = (currentIndex - 1 + menuItems.length) % menuItems.length;
          setActiveMenu(menuItems[prevIndex]);
          menuRefs.current[prevIndex].focus();
          break;
        case RC_RIGHT:
          setIsContentFocused(true); // Move focus to the content area
          contentRef.current.focus();
          break;
        case RC_ENTER:
          handleMenuClick(activeMenu);
          break;
        case RC_EXIT:
          navigate('/Menu');
          break;
        default:
          break;
      }
    }
  };
  

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeMenu, isContentFocused]);

  return (
    <div className='hotel-info-container'>
      {/* <div className='hotel-info-header'>
        HOTEL INFORMATION
      </div> */}
      
      <div className='hotel-info-content-container'>
        <div className='hotel-info-sidebar'>
        <div className='title-menu'><h1>HOTEL INFORMATION</h1></div>
          <ul className="sidebar-menu">
            {menuItems.map((menu, index) => (
              <li 
                key={menu}
                ref={(el) => (menuRefs.current[index] = el)}
                className={`sidebar-menu-item ${activeMenu === menu ? 'active' : ''}`}
                onClick={() => handleMenuClick(menu)}
                tabIndex={0}
              >
                {menu}
              </li>
            ))}
          </ul>
        </div>

        <div
          className='hotel-info-content'
          tabIndex={0} // Make the content div focusable
          ref={contentRef}
        >
         


          {activeMenu === 'Room' && 
          <>
            <div><h3>ROOM</h3></div>
            <div className='hotel-description-container'>
              <h3>Classic Deluxe Room</h3>
              {/* Content */}
              <div style={{display:'flex'}}>

                <div style={{padding:'5px'}}><img src={myimg} alt='' width='100%' style={{float:'left'}}></img></div>
                <div style={{padding:'5px'}}><img src={myimg} alt='' width='100%' ></img></div>
                
                </div>
              <p>Escape from your daily routine in true Ayodya style in a spacious classic deluxe room overlooking lush tropical gardens. Enjoy generous 48 sqm interiors, designed in subtle tones with timber fittings, traditional Balinese artworks, a private outdoor balcony, and thoughtfully curated amenities. The comfortable bedroom and contemporary marble bathroom are air-conditioned and can be appointed with a king-size bed or two twin-size beds.</p>
             
              {/* Add more content as needed */}


              <h3>Superior Room</h3>
              {/* Content */}
              <div style={{display:'flex'}}>

                <div style={{padding:'5px'}}><img src={myimg} alt='' width='100%' style={{float:'left'}}></img></div>
                <div style={{padding:'5px'}}><img src={myimg} alt='' width='100%' ></img></div>
                
                </div>
              <p>Escape from your daily routine in true Ayodya style in a spacious classic deluxe room overlooking lush tropical gardens. Enjoy generous 48 sqm interiors, designed in subtle tones with timber fittings, traditional Balinese artworks, a private outdoor balcony, and thoughtfully curated amenities. The comfortable bedroom and contemporary marble bathroom are air-conditioned and can be appointed with a king-size bed or two twin-size beds.</p>
             








            </div>
          </>
          }
          {activeMenu === 'Restaurant' && 
          
          <>
            <div><h3>RESTARURANT</h3></div>
            <div className='hotel-description-container'>
            <h3>Superior Room</h3>
              {/* Content */}
              <div style={{display:'flex'}}>

                <div style={{padding:'5px'}}><img src={myimg2} alt='' width='100%' style={{float:'left'}}></img></div>
               
                
                </div>
              <p>Escape from your daily routine in true Ayodya style in a spacious classic deluxe room overlooking lush tropical gardens. Enjoy generous 48 sqm interiors, designed in subtle tones with timber fittings, traditional Balinese artworks, a private outdoor balcony, and thoughtfully curated amenities. The comfortable bedroom and contemporary marble bathroom are air-conditioned and can be appointed with a king-size bed or two twin-size beds.</p>
             






              
            </div>
          </>
          
          
          
          
          
          
          
          }
          {activeMenu === 'Facilities' && <div><h3>FACILITIES</h3></div>}
        </div>
      </div>
    </div>
  );
}

export default Hotel;
