import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Menu.css'; // Optional: Add your fullscreen styles here

const FullscreenVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { videoSrc, channelName } = location.state || {};

  useEffect(() => {
    if (!videoSrc) {
      navigate('/watchtv'); // If no videoSrc, go back to the WatchTV page
    }
  }, [videoSrc, navigate]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'Backspace':
        case 'Escape':
          e.preventDefault(); // Prevent default behavior
          navigate('/watchtv'); // Navigate back to the WatchTV page on Backspace or Escape
          break;
        case 'f': // Toggle fullscreen on 'f' key press
          toggleFullscreen();
          break;
        default:
          break;
      }
    };

    const handlePopState = (event) => {
      // Handle popstate event by navigating to /watchtv
      navigate('/watchtv');
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else if (videoRef.current) {
      videoRef.current.requestFullscreen().catch(err => console.log(err));
      setIsFullscreen(true);
    }
  };

  return (
    <div className='fullscreen-video-container'>
      {videoSrc ? (
        <iframe
          ref={videoRef}
          src={videoSrc}
          title={channelName}
          width="100%"
          height="100%"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      ) : (
        <div className="video-placeholder">Loading video...</div>
      )}
      <button onClick={toggleFullscreen} className="fullscreen-toggle-btn">
        {isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      </button>
    </div>
  );
};

export default FullscreenVideo;
